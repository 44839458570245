@use "~@juullabs/react-components/src/styles/atom";
@use "@juullabs/react-components/src/styles/mixins";

.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: atom.px(0 base, padding, element);
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: atom.getPadding(layout, wider);

  @include mixins.breakpoint(tablet) {
    max-width: 400px;
  }
}

.button {
  margin-bottom: atom.getMargin();
}

.forgotPasswordlink {
  @include atom.font-caption-base-regular;
  align-self: flex-start;
  margin-bottom: atom.getMargin(narrow);
}

.textInput {
  width: 100%;
  margin-bottom: atom.getMargin();
}

.header {
  text-align: center;
  @include atom.font-body-base-regular;
  margin-bottom: atom.getMargin(layout, wider);
}

.disclaimer {
  text-align: center;
  @include atom.font-caption-base-regular;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
