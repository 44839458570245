@import '@viewlio/juulio-bridge/styles/core/variables';

.message {
  background-color: $grey5;
  color: $white;
  padding: 20px;
  text-align: center;

  a.compliance-error-link {
    cursor: pointer;
    text-decoration: underline;
  }
}

.success {
  background-color: $autoship-green;
}

.error {
  background-color: $error-red;
}
