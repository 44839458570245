@use "~@juullabs/react-components/src/styles/atom";


.infoIcon {
  background: atom.getColor(cement, lighter);
  color: atom.getColor(ink);
  font-weight: 700;
  padding: 3px 30px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
}
